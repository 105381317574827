body {
    color: $text-color;
    background: #fff;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

strong {
    font-weight: 900;
}

a {
    color: $brand-color;
}

h1 {
    font-size: 46px;
    line-height: 55px;
    color: $headline-color;
    font-weight: 900;
    @include media('<phablet') {
        font-size: 32px;
        line-height: 38px;
    }
}
.section-title {
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    font-weight: 900;
    color: $headline-color;
    + * {
        margin-top: 4 * $gutter;
    }
    @include media('<tablet') {
        + * {
            margin-top: 3 * $gutter;
        }
    }
    @include media('<phablet') {
        font-size: 26px;
        line-height: 31px;
        + * {
            margin-top: 2 * $gutter;
        }
    }
}