body {
    min-height: 100vh;
}

img {
    display: inline-block;
    max-width: 100%;
}
button {
    font-size: 18px;
    line-height: 2.5;
    font-weight: 900;
    background-color: $brand-color;
    color: #fff;
    padding: 0 (2 * $gutter);
    @include media('<phablet') {
        padding: 0 ($gutter);
    }
}
p {
    + * {
        margin-top: $gutter;
        @include media('<phablet') {
            margin-top: $gutter / 2;
        }
    }
}
h3,
h4 {
    + * {
        margin-top: $gutter;
        @include media('<phablet') {
            margin-top: $gutter / 2;
        }
    }
}

.grid {
    width: 100%;
    max-width: $grid-width;
    padding: 0 $gutter;
    margin: 0 auto;
}
section {
    padding: 4 * $gutter 0;
    @include media('<tablet') {
        padding: 3 * $gutter 0;
    }
    @include media('<phablet') {
        padding: 2 * $gutter 0;
    }
}
header {
    background-color: $brand-color;
    padding: (1.5 * $gutter) 0;
    @include media('<phablet') {
        padding: ($gutter) 0;
    }
    .grid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .menu {
            margin-left: auto;
            ul {
                display: flex;
                list-style-type: none;
                padding: 0;
                li {
                    background-color: transparent;
                    transition: background-color .3s ease;
                    a {
                        font-size: 18px;
                        line-height: 44px;
                        padding: 0 24px;
                        font-weight: 900;
                        color: #fff;
                        text-decoration: none;
                        transition: color .3s ease;
                    }
                    &:hover {
                        a {
                            color: rgba(255, 255, 255, .7);
                        }
                    }
                    &.active {
                        background-color: #fff;
                        a {
                            color: $brand-color;
                        }
                    }
                }
            }
        }
        @include media('<phablet') {
            justify-content: center;
            .logo {
                img {
                    width: 90px;
                    height: 82px;
                }
            }
            .menu {
                width: 100%;
                margin: $gutter 0 0;
                padding: $gutter 0 0;
                border-top: 1px solid #fff;
                ul {
                    justify-content: center;
                    li {
                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    &.login-header {
        height: 12 * $gutter;
        padding: (3 * $gutter) 0;
        justify-content: center;
        .grid {
            justify-content: center;
        }
        @include media('<phablet') {
            height: 6.5 * $gutter;
            padding: (1 * $gutter) 0;
        }
    }
}

.hero {
    background-color: $brand-color;
    .grid {
        position: relative;
        z-index: 0;
        &::after {
            content: '';
            @include fill-parent(absolute, -1);
            background: transparent url('../images/svg/default_hosting_ilustration.svg') no-repeat right $gutter top;
        }
    }
    .hero-text-block {
        color: #fff;
        width: 100%;
        max-width: 380px;
        position: relative;
        h1 {
            color: #fff;
            + * {
                margin-top: 36px;
            }
        }
        p {
            font-size: 20px;
            line-height: 32px;
            + * {
                margin-top: 36px;
            }
        }
    }
    @include media('<tablet') {
        .grid {
            &::after {
                background-size: contain;
                background-position: center center;
                opacity: .5;
            }
        }
        .hero-text-block {
            h1 {
                + * {
                    margin-top: $gutter;
                }
            }
            p {
                + * {
                    margin-top: $gutter;
                }
            }
        }
	}
}

.faq {
    .grid {
        .article-group {
            display: flex;
            flex-wrap: wrap;
            margin-left: -1.5 * $gutter;
            margin-right: -1.5 * $gutter;
            article {
                width: calc(50% - (3 * #{$gutter}));
                margin: 1.5 * $gutter;
                display: flex;
                flex-wrap: wrap;
                figure {
                    width: 8 * $gutter;
                    height: 8 * $gutter;
                    margin-right: 2 * $gutter;
                }
                .article-info {
                    width: calc(100% - (10 * #{$gutter}));
                    margin-left: auto;
                }
                h4 {
                    font-size: 22px;
                    line-height: 27px;
                    font-weight: 900;
                    color: $headline-color;
                    @include media('<phablet') {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
            @include media('<desktop') {
                margin-left: 0;
                margin-right: 0;
                article {
                    width: 100%;
                    max-width: 520px;
                    margin: $gutter auto;
                    text-align: center;
                    justify-content: center;
                    figure {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: $gutter;
                    }
                    .article-info {
                        width: 100%;
                        margin-left: 0;
                    }
                }
            }
            @include media('<phablet') {
                article {
                    margin: $gutter / 2 auto;
                    figure {
                        width: 4.5 * $gutter;
                        height: 4.5 * $gutter;
                    }
                }
            }
        }
    }
}

.instructions {
    .instructions-type {
        display: flex;
        justify-content: center;
        margin-left: -3.5 * $gutter;
        margin-right: -3.5 * $gutter;
        li {
            margin: 0 (3.5 * $gutter);
            text-align: center;
            figure {
                width: 8 * $gutter;
                height: 8 * $gutter;
                figcaption {
                    display: block;
                    margin-top: $gutter / 3;
                    a {
                        text-decoration: none;
                    }
                }
            }
        }
        + * {
            margin-top: 5 * $gutter;
        }
        @include media('<phablet') {
            margin-left: -$gutter;
            margin-right: -$gutter;
            li {
                margin: 0 ($gutter);
                figure {
                    width: 4.5 * $gutter;
                    height: 4.5 * $gutter;
                }
            }
        }
    }
    .instructions-list {
        display: flex;
        justify-content: space-between;
        margin-left: -$gutter;
        margin-right: -$gutter;
        flex-wrap: wrap;
        li {
            width: calc(33% - (2 * #{$gutter}));
            padding: $gutter;
            h4 {
                font-size: 22px;
                line-height: 27px;
                color: $headline-color;
                font-weight: 900;
            }
            &:not(:first-child) {
                border-left: 2px dashed $border-color;
            }
        }
        @include media('<desktop') {
            margin-left: 0;
            margin-right: 0;
            li {
                width: 100%;
                max-width: 520px;
                margin: 0 auto;
                &:not(:first-child) {
                    border-left: 0;
                    border-top: 2px dashed $border-color;
                }
            }
        }
        @include media('<phablet') {
            li {
                h4 {
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }
}

.login {
    background-color: $brand-color;
    min-height: calc(100vh - (12 * #{$gutter}));
    #login-form {
        width: 100%;
        max-width: 580px;
        margin: 0 auto;
        padding: 2 * $gutter;
        background-color: #fff;
        border-radius: 8px;
        .field {
            display: flex;
            align-items: center;
            label {
                font-weight: 900;
                width: 33.33%;
            }
            input {
                border: 1px solid $border-color-alt;
                line-height: 2.5 * $gutter;
                padding: 0 1rem;
                width: 66.66%;
                background: #fff;
                font-size: 16px;
                font-weight: 400;
                outline: none;
                box-shadow: none;
                caret-color: $brand-color;
                transition: border .3s ease;
                &:focus {
                    border: 1px solid $brand-color;
                }
            }
            .select-wrapper {
                width: 66.66%;
                position: relative;
                select {
                    border: 1px solid $border-color-alt;
                    line-height: 2.5 * $gutter;
                    padding: 0 1rem;
                    appearance: none;
                    display: block;
                    width: 100%;
                    background: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    outline: none;
                    box-shadow: none;
                    transition: border .3s ease;
                    cursor: pointer;
                    option {
                        cursor: pointer;
                    }
                    &:focus {
                        border: 1px solid $brand-color;
                    }
                }
                &::after {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 12px 10.5px 0;
                    border-color: $brand-color transparent transparent;
                    right: 1rem;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                }
            }
            + .field {
                margin-top: 1.5 * $gutter;
            }
            &.field-action {
                button {
                    margin-left: auto;
                    cursor: pointer;
                }
                @include media('<phone') {
                    justify-content: center;
                    button {
                        margin-left: 0;
                        width: 100%;
                        order: -1;
                        margin-bottom: $gutter;
                    }
                }
            }
        }
    }
    @include media('<phablet') {
        min-height: calc(100vh - (6.5 * #{$gutter}));
        #login-form {
            padding: 1 * $gutter;
            .field {
                flex-wrap: wrap;
                label {
                    width: 100%;
                    + input,
                    + .select-wrapper {
                        margin-top: $gutter / 2;
                    }
                }
                input {
                    width: 100%;
                }
                .select-wrapper {
                    width: 100%;
                }
            }
        }
    }
}

footer {
    background-color: $gray-bg;
    padding: 3 * $gutter 0;
    @include media('<phablet') {
        padding: 1.5 * $gutter 0;
    }
    .article-group {
        display: flex;
        justify-content: space-between;
        margin: 0 (-$gutter);
        flex-wrap: wrap;
        article {
            width: calc(33% - (2 * #{$gutter}));
            margin: 0 $gutter;
            h4 {
                font-size: 18px;
                line-height: 27px;
                font-weight: 900;
                color: $headline-color;
            }            
        }
        @include media('<tablet') {
            margin: 0;
            article {
                width: 100%;
                max-width: 520px;
                margin: 0 auto;
                text-align: center;
                + article {
                    margin-top: $gutter;
                }
            }
        }
    }
}