$grid-width: 1180px;
$gutter: 20px;
$text-color: #303030;
$headline-color: #3f403f;
$brand-color: #f5821f;
$gray-bg: #f3f3f3;
$border-color: #A7A9AB;
$border-color-alt: #D1D2D4;

%clearfix {
    &::after {
        clear: both;
        display: table;
        content: '';
    }
}